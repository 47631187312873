.table td {
  font-size: 13px;
}
.result-info p {
  margin-bottom: 0.3rem !important;
}
.logo {
  margin: 10px auto;
  max-height: 150px;
}
.c-switch {
  margin-bottom: -7px !important;
  margin-left: 10px;
}
.c-switch[class*='-3d'].c-switch-lg
  .c-switch-input:checked
  ~ .c-switch-slider::before {
  transform: translateX(30px);
}

.c-switch[class*='-3d'].c-switch-lg {
  width: 60px;
  height: 25px;
}

.c-switch[class*='-3d'].c-switch-lg .c-switch-slider::before {
  width: 30px;
  height: 25px;
}
.icon-btn {
  cursor: pointer;
}
.icon-btn.trash {
  color: #e55353;
}
.logo-container img {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}
.c-sidebar-brand {
  padding: 10px;
}
.text-bold {
  font-weight: bold;
}
